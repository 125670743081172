// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// import 'virtual:windi.css'
import './user.scss'

import { createApp } from 'vue/dist/vue.esm-bundler'
import axios from 'axios'

const app = createApp({
  data() {
    return {
      mobileMenuDropdown: false,
      searching: false,
      searchInput: "",
      searchCoins: []
    }
  },
  methods: {
    togglemobileMenuDropdown() {
      this.mobileMenuDropdown = !this.mobileMenuDropdown
    },
    onFocusSearch() {

      if (this.searchCoins.length) return

      this.submitSearchCoins()
    },

    onInputSearch(e) {
      this.submitSearchCoins(e.target.value)
    },

    async submitSearchCoins(q = null) {
      try {
        this.searching = true
        const { data } = await axios.get('/cryptocurrencies.json', { params: { q: q } })
        this.searchCoins = data
      } catch (error) {

      } finally {
        this.searching = false
      }
    },

    async addWatchlist(e, slug) {
      let thisEl = e.target

      const { data } = await axios.post('/user/watchlist', { slug: slug })

      if (data.code == "add") {
        thisEl.classList.add('fill-red-500')
        thisEl.classList.add('stroke-red-500')
      }
      if (data.code == "remove") {
        thisEl.classList.remove('fill-red-500')
        thisEl.classList.remove('stroke-red-500')
      }
    },
    redirectToLogin() {
      location.href = '/users/sign_in'
    }
  },
  components: { },
}).mount('[data-behavior="vue"]')
